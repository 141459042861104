import type { FeaturevisorInstance } from '@featurevisor/sdk';
import type { OrganizationContextSchemaT } from '../contexts/organization-context';

export const ToolsTalentsInviteFeature = (
  sdk: FeaturevisorInstance,
  context: OrganizationContextSchemaT
) => {
  const key = 'tools-talents-invite';

  return {
    key,
    isEnabled: sdk.isEnabled(key, context),
    withInviteLink: (sdk.getVariableArray(key, 'withInviteLink', context) ??
      []) as ('classic' | 'email-format')[],
  };
};
