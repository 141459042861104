import type { FeaturevisorInstance } from '@featurevisor/sdk';
import type { OrganizationSuperAdminContextSchemaT } from '../contexts/organization-super-admin-context';

export const ToolsAdminsToolersFeature = (
  sdk: FeaturevisorInstance,
  context: OrganizationSuperAdminContextSchemaT
) => {
  const key = 'tools-admins-toolers';

  const isEnabled = sdk.isEnabled(key, context);

  return {
    key,
    isEnabled,
    withManageAccessMenu:
      sdk.getVariableBoolean(key, 'withManageAccessMenu', context) && isEnabled,
    withDashboardStats:
      sdk.getVariableBoolean(key, 'withDashboardStats', context) && isEnabled,
  };
};
