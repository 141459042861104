import type { FeaturevisorInstance } from '@featurevisor/sdk';
import type { OrganizationSuperAdminContextSchemaT } from '../contexts/organization-super-admin-context';

export const ToolsSidebarFeature = (
  sdk: FeaturevisorInstance,
  context: OrganizationSuperAdminContextSchemaT
) => {
  const key = 'tools-sidebar';

  return {
    key,
    isEnabled: sdk.isEnabled(key, context),
    withAdministratorMenu: sdk.getVariableBoolean(
      key,
      'withAdministratorMenu',
      context
    ),
    withStatistics: sdk.getVariableBoolean(key, 'withStatistics', context),
    languageItem: sdk.getVariableString(key, 'languageItem', context) as
      | 'hybrid'
      | 'all-french'
      | undefined,
  };
};
