import { env } from '@prismo-io/env';
import umami from '@umami/node';
import {
  type EventPayload,
  type UserProps,
  usermavenClient,
} from '@usermaven/sdk-js';
import { UmamiClientMocked, UsermavenClientMocked } from './mock';
import type { Usermaven } from './types';

type Config = {
  usermavenKey?: string;
  umamiKey?: string;
  log?: boolean;
};

const createUsermaven = (apiKey: string = env.NEXT_PUBLIC_USERMAVEN_KEY) => {
  if (apiKey && apiKey.trim() !== '') {
    return usermavenClient({
      key: apiKey,
      tracking_host: 'https://events.usermaven.com',
      autocapture: typeof document !== 'undefined',
      privacy_policy: 'strict',
      randomize_url: false,
    });
  }

  return UsermavenClientMocked;
};

const createUmami = (
  websiteId: string | undefined = env.NEXT_PUBLIC_UMAMI_WEBSITE_ID
) => {
  if (websiteId && websiteId.trim() !== '') {
    const hostUrl =
      typeof document !== 'undefined'
        ? '/api/tracking'
        : `${env.UMAMI_TRACKING_URL}`;

    umami.init({
      websiteId,
      hostUrl,
    });
    return umami;
  }

  return UmamiClientMocked;
};

export const Wrapper = (opts?: Config): Usermaven => {
  const { usermavenKey, umamiKey, log = false } = opts || {};

  const usermaven = createUsermaven(usermavenKey);
  const umami = createUmami(umamiKey);

  return {
    id: async (userData: UserProps, doNotSendEvent = false) => {
      if (log) {
        console.groupCollapsed('Tracking ID');
        console.log('UserData =>', userData);
        console.log('doNotSendEvent =>', doNotSendEvent);
        console.groupEnd();
      }

      usermaven.id(userData, doNotSendEvent);
      umami.identify(userData);
    },
    track: async (typeName: string, payload?: EventPayload) => {
      if (log) {
        console.groupCollapsed('Usermaven Track =>', typeName);
        console.log('typeName =>', typeName);
        console.log('payload =>', payload);
        console.groupEnd();
      }

      usermaven.track(typeName, payload);
      umami.track(typeName, payload);
    },
  };
};
