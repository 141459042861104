import { z } from 'zod';

export const HubspotTrackArgs = z
  .object({
    cliquer_app_lead_teasing: z.boolean().nullish(),
    cliquer_onboarding_voir_mes_resultats: z.boolean().nullish(),
  })
  .transform((props) => {
    return {
      _properties: props,
    };
  });

export type HubspotTrackArgsT = z.infer<typeof HubspotTrackArgs>;

export const HubspotTrackReturns = z.boolean();

export type HubspotTrackReturnsT = z.infer<typeof HubspotTrackReturns>;
