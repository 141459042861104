'use client';

import 'client-only';

import { ToolsAdminsToolersFeature } from '../common/features/tools-admins-toolers-feature';
import { createUseFlag } from './create-use-flag';

export const useToolsAdminsToolersFeature = createUseFlag(
  ToolsAdminsToolersFeature
);
