import type { FeaturevisorInstance } from '@featurevisor/sdk';
import type { OrganizationContextSchemaT } from '../contexts/organization-context';

export const ToolsAppLinkFeature = (
  sdk: FeaturevisorInstance,
  context: OrganizationContextSchemaT
) => {
  const key = 'tools-app-link';

  const isEnabled = sdk.isEnabled(key, context);

  return {
    key,
    isEnabled,
  };
};
