import { env } from '@prismo-io/env';
import umami from '@umami/node';
import { usermavenClient } from '@usermaven/sdk-js';
import { UsermavenClientMocked } from './mock';
import { Wrapper } from './wrapper';

export const createTrackingClient = (
  apiKey: string = env.NEXT_PUBLIC_USERMAVEN_KEY
) => {
  // if (apiKey && apiKey.trim() !== '') {
  //   return usermavenClient({
  //     key: apiKey,
  //     tracking_host: 'https://events.usermaven.com',
  //     autocapture: typeof document !== 'undefined',
  //     privacy_policy: 'strict',
  //     randomize_url: false,
  //   });
  // }

  // return UsermavenClientMocked;
  return Wrapper();
};
