import type { FeaturevisorInstance } from '@featurevisor/sdk';
import type { OrganizationContextSchemaT } from '../contexts/organization-context';

type resourcesProps = {
  title: string;
  link: string;
  img: string;
};

export const ToolsResourcesFeature = (
  sdk: FeaturevisorInstance,
  context: OrganizationContextSchemaT
) => {
  const key = 'tools-resources';

  return {
    key,
    isEnabled: sdk.isEnabled(key, context),
    discoverTools: sdk.getVariableString(key, 'discoverTools', context),
    talentPath: sdk.getVariableObject(key, 'talentPath', context) as
      | resourcesProps
      | undefined,
    expertPath: sdk.getVariableObject(key, 'expertPath', context) as
      | resourcesProps
      | undefined,
    recruitment: sdk.getVariableObject(key, 'recruitment', context) as
      | resourcesProps
      | undefined,
    internalSupport: sdk.getVariableObject(key, 'internalSupport', context) as
      | resourcesProps
      | undefined,
    customerPitch: sdk.getVariableObject(key, 'customerPitch', context) as
      | resourcesProps
      | undefined,
    tipsAndTricks: sdk.getVariableObject(key, 'tipsAndTricks', context) as
      | resourcesProps
      | undefined,
    checkListAura: sdk.getVariableObject(key, 'checkListAura', context) as
      | resourcesProps
      | undefined,
    checkListHelio: sdk.getVariableObject(key, 'checkListHelio', context) as
      | resourcesProps
      | undefined,
    checkListAnalysisCriteria: sdk.getVariableObject(
      key,
      'checkListAnalysisCriteria',
      context
    ) as resourcesProps | undefined,
    helperDashboard: sdk.getVariableString(key, 'helperDashboard', context),
    helperResources: sdk.getVariableString(key, 'helperResources', context),
    helperExperienceConfiguration: sdk.getVariableString(
      key,
      'helperExperienceConfiguration',
      context
    ),
    helperInvitations: sdk.getVariableString(key, 'helperInvitations', context),
    helperProjects: sdk.getVariableString(key, 'helperProjects', context),
    helperDiagnostic: sdk.getVariableString(key, 'helperDiagnostic', context),
    helperPrismotheque: sdk.getVariableString(
      key,
      'helperPrismotheque',
      context
    ),
  };
};
