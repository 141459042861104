'use client';

import { env } from '@prismo-io/env';
import {
  type Database,
  LanguageCode,
  type LanguageCodeT,
} from '@prismo-io/schemas';
import { createBrowserClient } from '@supabase/ssr';
import type { SupabaseClient } from '@supabase/supabase-js';

type Options = {
  locale: LanguageCodeT;
};

const DEFAULT_OPTIONS: Options = {
  locale: LanguageCode.Enum.fr,
};

export const createApiClient = (
  options: Partial<Options> = DEFAULT_OPTIONS
): SupabaseClient<Database> => {
  const { locale } = {
    ...DEFAULT_OPTIONS,
    ...options,
  };

  const client = createBrowserClient<Database>(
    `${env.NEXT_PUBLIC_BASE_API_URL}/api`,
    env.NEXT_PUBLIC_SUPABASE_ANON_KEY,
    {
      auth: {
        flowType: 'pkce',
        storageKey: env.NEXT_PUBLIC_COOKIE_NAME,
      },
      global: {
        headers: {
          'x-language': locale,
          // 'po:organization': cookies()['po:organization'] ?? null,
        },
        fetch: (input, init = {}) => {
          return fetch(input, { ...init, credentials: 'same-origin' });
        },
      },
      cookieOptions: {
        domain: env.NEXT_PUBLIC_COOKIE_DOMAIN,
        name: env.NEXT_PUBLIC_COOKIE_NAME,
        sameSite: 'lax',
        path: '/',
        secure: env.NEXT_PUBLIC_APP_ENV !== 'development',
      },
    }
  );

  client.realtime.endPoint =
    `${env.NEXT_PUBLIC_SUPABASE_URL}/realtime/v1/websocket`.replace(
      /^http/i,
      'ws'
    );

  return client;
};
