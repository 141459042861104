'use client';

import { FeaturevisorProvider } from '@featurevisor/react';
import {
  type FeaturevisorInstance,
  createInstance,
  createLogger,
} from '@featurevisor/sdk';
import type { DatafileContent } from '@featurevisor/types';
import { type FC, type PropsWithChildren, useEffect, useState } from 'react';

type Props = {
  config: DatafileContent;
};

export const ClientFlagsProvider: FC<PropsWithChildren<Props>> = (props) => {
  const { config, children } = props;

  const [flags] = useState<FeaturevisorInstance>(() =>
    createInstance({
      datafile: config,
    })
  );

  return (
    <FeaturevisorProvider instance={flags}>{children}</FeaturevisorProvider>
  );
};
