'use client';

import 'client-only';

import { useSdk, useStatus } from '@featurevisor/react';
import type { FeaturevisorInstance } from '@featurevisor/sdk';
import { useMemo } from 'react';

type FlagFeatureFn = (sdk: FeaturevisorInstance, context: any) => any;

export function createUseFlag<F extends FlagFeatureFn = FlagFeatureFn>(fn: F) {
  return (ctx: Parameters<F>[1]): ReturnType<typeof fn> => {
    const sdk = useSdk();

    const { isReady } = useStatus();

    return useMemo(() => fn(sdk, ctx), [sdk, ctx, isReady]);
  };
}
