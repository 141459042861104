import type { Umami } from '@umami/node';
import type { EventPayload, UserProps } from '@usermaven/sdk-js';
import type { Usermaven } from './types';

export const UsermavenClientMocked: Usermaven = {
  id: async (userData: UserProps, doNotSendEvent = false) => {},
  track: async (typeName: string, payload?: EventPayload) => {},
};

export const UmamiClientMocked: Pick<Umami, 'identify' | 'track'> = {
  identify: (properties?: object) => {},
  track: (event, data) => {},
} as Pick<Umami, 'identify' | 'track'>;
